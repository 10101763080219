<template>
  <v-container v-if="recipe" class="recipe-detail wp-page">
    <v-row class="px-3">
      <v-col cols="12" class="my-4">
        <router-link
          class="d-flex align-center recipe-detail-go-back black--text"
          :to="{
            name: 'RecipeList'
          }"
        >
          <v-icon color="primary" class="pr-2">$chevronLeft</v-icon>
          <span class="font-weight-bold primary--text">{{
            $t("recipes.goBack")
          }}</span></router-link
        >
      </v-col>
      <v-col cols="12" class="my-4">
        <h1 class="mb-4" v-html="recipe.title.rendered"></h1>
        <div class="mb-4 d-flex flex-wrap align-center justify-space-between">
          <h4 class="primary--text font-weight-bold">{{ recipe.course }}</h4>
          <div class="d-flex align-center flex-wrap justify-space-between">
            <span class="pr-4">{{ $t("recipes.share") }}</span>
            <SocialShare></SocialShare>
          </div>
        </div>

        <iframe
          v-if="recipe.video_url"
          allow="fullscreen"
          :src="loadURL(this.recipe.video_url)"
        ></iframe>
        <v-img
          v-else
          class="mb-3 rounded-md"
          :src="recipe.featured_image_url"
          :alt="`ricetta-${recipe.slug}`"
        />
        <v-row>
          <v-col cols="auto" sm="auto" v-if="recipe.rating"
            >{{ $t("recipes.difficulty") }}:
            <strong class="ml-2">{{ recipe.rating }}</strong></v-col
          >
          <v-col cols="auto" sm="auto" v-if="recipe.recipe_cook_time"
            >{{ $t("recipes.cookingTime") }}:<strong class="ml-2"
              >{{ recipe.recipe_cook_time }}
              {{ recipe.recipe_cook_time_text }}</strong
            ></v-col
          >
          <v-col cols="auto" sm="auto" v-if="recipe.recipe_prep_time"
            >{{ $t("recipes.prepTime") }}:
            <strong class="ml-2"
              >{{ recipe.recipe_prep_time }}
              {{ recipe.recipe_prep_time_text }}</strong
            ></v-col
          >
        </v-row>
      </v-col>
      <v-col
        v-if="recipe.recipe_ingredients && recipe.recipe_ingredients[0].length"
        cols="12"
        class="recipe-detail-ingredients"
      >
        <v-list class=" w-100 rounded-md">
          <h2 class="px-3 text-h1 font-weight-bold">
            {{ $t("recipes.ingredients.title") }}
          </h2>
          <v-list-item
            v-for="ingredient in ingredients"
            :key="ingredient.ingredient_id"
            class="ingrediets-list px-0"
          >
            <v-list-item-content>
              <IngredientCard v-bind:product="ingredient" />
              <v-divider></v-divider>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="ingrediets-list px-0 d-flex">
            <v-list-item-action
              class="d-flex flex-column flex-sm-row align-center justify-center justify-sm-space-between justify-md-end w-100 mr-0 px-4"
              v-if="
                recipe.recipe_ingredients && recipe.recipe_ingredients[0].length
              "
            >
              <!--my-5 px-3 w-100 justify-end align-center text-right d-flex -->
              <v-btn
                outlined
                large
                color="secondary"
                class="recipe-btn add-all-to-list bg-white rounded-pill black--text mb-5 mb-sm-0"
                data-test="recipe-add-all-to-list"
                elevation="0"
                @click="addAllToFavorites"
              >
                {{ $t("recipes.addItem") }}
              </v-btn>
              <v-btn
                large
                elevation="0"
                color="primary"
                class="ml-md-5 recipe-btn add-all-to-cart default--text rounded-pill"
                data-test="recipe-add-all-to-cart"
                @click="addAllToCart"
              >
                {{ $t("recipes.addToCart") }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row class="my-4 px-3">
      <v-col>
        <!-- <h2>{{ $t("recipes.prepTime") }}:</h2> -->
        <p v-html="recipe.content.unrendered"></p>
        <div v-if="recipe.box_2" v-html="recipe.box_2"></div>
        <div v-if="recipe.box_3" v-html="recipe.box_3"></div>
        <div v-if="recipe.box_4" v-html="recipe.box_4"></div>
        <div class="d-flex align-center">
          <span class="pr-4 my-6">Condividi</span> <SocialShare></SocialShare>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="relatedRecipes"
      class="d-flex align-center justify-center px-3 my-4"
    >
      <h2 class="mr-auto">Altre ricette simili</h2>
      <swiper :options="swiperOptions" ref="swiperRef" class="my-4">
        <swiper-slide v-for="recipe in relatedRecipes" :key="recipe.ID">
          <PostCard type="recipe" :post="recipe"></PostCard>
        </swiper-slide>
      </swiper>
      <v-btn
        class="text-center"
        color="primary"
        :to="{
          name: 'RecipeList'
        }"
        >Torna alle Ricette</v-btn
      >
    </v-row>
  </v-container>
</template>
<style lang="scss">
.recipe-detail {
  .add-all-to-list,
  .add-all-to-cart {
    width: 100%;
    @media screen and (min-width: 600px) {
      width: auto;
    }
  }
  .add-all-to-list {
    color: #000000 !important;
  }

  &-go-back {
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }
  &-ingredients {
    .v-list {
      border: 1px solid var(--v-accent-base);
    }
  }
}
</style>
<script>
import IngredientCard from "@/components/product/IngredientsCard.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import loadURL from "~/mixins/loadURL";
import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";
import productMixin from "~/mixins/product";
import login from "~/mixins/login";
import CMService from "~/service/cmService";
import ProductService from "~/service/productService";
import CartService from "~/service/cartService";
import ListService from "~/service/listService";
import { mapActions } from "vuex";
import SocialShare from "@/components/wordpress/SocialShare.vue";
import PostCard from "@/components/wordpress/PostCard.vue";
import get from "lodash/get";

export default {
  name: "RecipeDetail",
  mixins: [clickHandler, categoryMixins, productMixin, login, loadURL],
  components: {
    IngredientCard,
    SocialShare,
    PostCard
  },
  jsonld() {
    if (this.recipe) {
      const ingredients = this.recipe.recipe_ingredients[0].map(ingredient =>
        `${ingredient.amount} ${ingredient.unit} di ${ingredient.ingredient}`.trim()
      );

      const recipeTimeTextMap = {
        min: "M",
        mins: "M",
        minute: "M",
        minutes: "M",
        minuti: "M",
        hour: "H",
        hours: "H",
        ora: "H",
        ore: "H"
      };

      const formattedPrepTimeText =
        recipeTimeTextMap[this.recipe.recipe_prep_time_text.toLowerCase()];

      const formattedCookTimeText =
        recipeTimeTextMap[this.recipe.recipe_cook_time_text.toLowerCase()];

      const prepTime = `PT${this.recipe.recipe_prep_time}${formattedPrepTimeText}`;
      const cookTime = `PT${this.recipe.recipe_cook_time}${formattedCookTimeText}`;

      const totalTime = `PT${this.$dayjs
        .duration(prepTime)
        .add(this.$dayjs.duration(cookTime))
        .toISOString()}`;

      const recipeInstructions = this.recipe.recipe_instructions[0];

      const recipeUrl =
        "https://alisupermercati.it/ricette/" + this.recipe.slug;

      const transformedInstructions = recipeInstructions.map(instruction => {
        return {
          "@type": "HowToStep",
          name: instruction.group,
          text: instruction.description,
          url: recipeUrl,
          image: instruction.image
        };
      });

      return {
        "@context": "https://schema.org/",
        "@type": "Recipe",
        name: this.recipe.title.rendered,
        image: this.recipe.featured_image_url,
        author: {
          "@type": "Organization",
          name: "Alí Supermercati"
        },
        datePublished: this.$dayjs(this.recipe.date).format("YYYY-MM-DD"),
        description: this.recipe.raw_excerpt,
        prepTime: prepTime,
        cookTime: cookTime,
        totalTime: totalTime,
        keywords: this.recipe.tags_name.join(","),
        recipeCategory: this.recipe.course,
        recipeCuisine: this.recipe.cuisine,
        recipeIngredient: ingredients,
        recipeInstructions: transformedInstructions,
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: this.recipe.stars
        },
        video: {
          "@type": "VideoObject",
          name: this.recipe.title.rendered,
          description: this.recipe.raw_excerpt,
          thumbnailUrl: this.recipe.featured_image_url,
          embedUrl: this.recipe.video_url
        }
      };
    }
  },
  data() {
    return {
      content: null,
      recipe: null,
      products: null,
      ingredients: [],
      items: [],
      relatedRecipes: null,
      swiperOptions: {
        slidesPerGroup: 1,
        breakpoints: {
          600: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 15
          },
          960: {
            slidesPerView: 3,
            slidesPerGroup: 3
          },
          1264: {
            slidesPerView: 4,
            spaceBetween: 20,
            slidesPerGroup: 4
          }
        },
        // slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `.product-list-pagination`,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev`,
          nextEl: `#product-list-slider-next`
        }
      },
      currentDate: null,
      cssUrl: ""
    };
  },

  async mounted() {
    const timestamp = new Date().getTime();
    this.currentDate = timestamp;
    const cssUrl = `/p/wp-content/themes/ali/styles-common.css?cache_buster=${this.currentDate}`;

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = cssUrl;

    document.head.appendChild(link);

    await this.fetchRecipe(this.$route.params.postName);
    await this.fetchRelatedRecipes(this.recipe.course);
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    async fetchRecipe(postName) {
      this.ingredients = [];
      const res = await CMService.getCustomPostByFilters("recipe", [
        {
          filterName: "filter[recipe]",
          filterValue: postName
        }
      ]);

      if (res && res.data && res.data.length) {
        this.recipe = res.data[0];
        const wpIngredients = this.recipe.recipe_ingredients[0];
        const ids = wpIngredients.map(product => Number(product.notes));
        const ingredients = await ProductService.getProductsByIds(ids);
        this.products = ingredients.products;
        console.log("PRODUCTS", this.products);
        for (const ingredient of wpIngredients) {
          let objIngr = ingredients.products.find(
            ({ productId }) => productId == ingredient.notes
          );
          if (objIngr) {
            objIngr.existsInShop = true;
            const quantity = CartService.plus(objIngr, 0, false);
            this.items.push({ product: objIngr, quantity });
          } else {
            objIngr = {};
            objIngr.existsInShop = false;
          }

          objIngr.wp_amount = ingredient.amount;
          objIngr.wp_unit = ingredient.unit;
          objIngr.wp_ingredient = ingredient.ingredient;
          this.ingredients.push(objIngr);
        }
      }
    },
    async addAllToCart() {
      // let qty;
      if (await this.needLogin("addtocart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            for (const product of this.products) {
              // if (product.productInfos.TIPOLOGIA === "Sfuso") {
              //   qty = product.productInfos.STEP_SELLING;
              // } else {
              //   qty =
              //     product.productInfos.QTY_MULT || product.productInfos.MINACQ;
              // }
              const quantity = CartService.plus(product, 0, false);
              await this.addProduct({ product, quantity });
            }
          }
        }
      }
    },
    async addAllToFavorites() {
      if (await this.needLogin("addtocart")) {
        let vm = this;
        let res = await vm.$dialog.show(ListSelector, {
          waitForResult: true,
          fullscreen: vm.$vuetify.breakpoint.smAndDown,
          width: 300
        });
        // let newQuantity = CartService.plus(vm.product, vm.quantity);
        if (res) {
          await ListService.addProductsToList(res.listId, this.items);
        }
      }
    },
    async fetchRelatedRecipes(course) {
      let params = [];
      params.push({
        filterName: "filter[course]",
        filterValue: course
      });
      const res = await CMService.getCustomPostByFilters(
        "recipe",
        params,
        12,
        1
      );

      if (res && res.data && res.data.length) {
        this.relatedRecipes = res.data;
      } else {
        this.relatedRecipes = null;
      }
    }
  },
  watch: {
    "$route.params.postName"() {
      this.fetchRecipe(this.$route.params.postName);
    }
  },
  metaInfo() {
    if (this.recipe) {
      let courseVal = get(this.recipe, "course");
      let courseData = Array.isArray(courseVal)
        ? ""
        : ` - i nostri ${courseVal}`;
      let title = `${get(
        this.recipe,
        "title.rendered"
      )} ${courseData} - Ricette Alí`;
      let description =
        get(this.recipe, "meta.seo_description") ||
        `${get(this.recipe, "title.rendered")} ${courseData} - Difficoltà: ${
          this.recipe.rating
        } - cottura: ${this.recipe.recipe_cook_time} ${
          this.recipe.recipe_cook_time_text
        } - preparazione: ${this.recipe.recipe_prep_time} ${
          this.recipe.recipe_prep_time_text
        } - Ricette Alí`;

      `${get(this.recipe, "meta.seo_description")}`;
      return {
        title: title,
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}${this.$route.path}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: description
          },
          // Open Graph Metas
          {
            vmid: "og:locale",
            property: "og:locale",
            content: "it_IT"
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: title
          },
          {
            vmid: "og:type",
            property: "og:type",
            content: "website"
          },
          {
            vmid: "og:url",
            property: "og:url",
            content: location.href
          },
          {
            vmid: "og:image",
            property: "og:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            vmid: "og:image:alt",
            property: "og:image:alt",
            content: description
          },
          {
            vmid: "og:site_name",
            property: "og:site_name",
            content: global.config?.defaultPageName
          },
          // Twitter Metas
          {
            vmid: "twitter:card",
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            vmid: "twitter:title",
            name: "twitter:title",
            content: title
          },
          {
            vmid: "twitter:image",
            name: "twitter:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            vmid: "twitter:url",
            name: "twitter:url",
            content: location.href
          }
        ]
      };
    }
  }
};
</script>
