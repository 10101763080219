<template>
  <div class="cart-item w-100">
    <v-list-item
      :to="
        product.existsInShop
          ? { name: 'Product', params: { slug: product.slug } }
          : null
      "
      :class="$vuetify.breakpoint.xs ? 'is-checkout' : 'py-2 is-not-checkout'"
    >
      <v-row no-gutters class="w-100">
        <v-col cols="12" sm="6" class="d-flex align-center">
          <v-img
            contain
            height="auto"
            class="cart-item-image mr-3"
            v-if="product.existsInShop"
            :src="product.mediaURL"
            :alt="product.name"
            onerror="this.onerror=null;this.src='/no-icon.png'"
          ></v-img>

          <v-list-item-content class="align-start pl-0">
            <v-card-text class="text-body-2 pl-0">
              {{ product.wp_amount }} {{ product.wp_unit }}
              {{ product.wp_ingredient }}
            </v-card-text>
          </v-list-item-content>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-wrap flex-sm-nowrap justify-start align-start"
        >
          <template v-if="product.available > 0">
            <v-row no-gutters class="flex-sm-column flex-md-row align-center">
              <v-col cols="12" md="6">
                <ProductPrice
                  :product="product"
                  :isList="true"
                  class="text-body-1 "
                ></ProductPrice>
              </v-col>
              <v-col cols="12" md="6">
                <div
                  class="d-flex w-100 my-3 align-center justify-space-between justify-sm-start"
                >
                  <div
                    class="qty-wrap pa-1 rounded-sm mx-2 d-flex align-self-stretch"
                    :class="{ 'not-empty': quantity > 0 }"
                    transition="fab-transition"
                  >
                    <a
                      role="button"
                      class="minus"
                      v-ripple
                      @click.stop.prevent="minus"
                      @mousedown.stop
                      data-test="sub-qty-btn"
                    >
                      <!-- v-ripple -->
                      <v-icon>$minus</v-icon>
                    </a>
                    <div class="val-cnt">
                      <span class="val" data-test="cart-item-product-val"
                        >{{ quantity }} {{ unit }}</span
                      >
                      <span class="small">{{ quantityPerUnit }}</span>
                    </div>
                    <a
                      role="button"
                      class="plus"
                      v-ripple
                      @click.stop.prevent="plus"
                      @mousedown.stop
                      data-test="add-to-cart"
                    >
                      <!-- v-ripple -->
                      <v-icon>$plus</v-icon>
                    </a>
                  </div>
                  <v-btn
                    large
                    icon
                    class="primary rounded-sm add-to-cart-btn d-flex"
                    color="primary"
                    @click.stop.prevent="addToCart(quantity)"
                  >
                    <v-icon small>$cart</v-icon></v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </template>

          <div v-else class="not-available ml-auto">
            Il prodotto non è disponibile
          </div>
        </v-col>
      </v-row>
    </v-list-item>
  </div>
</template>
<style lang="scss" scoped>
.cart-item {
  &-image {
    max-width: 70px;
    height: min-content;
  }
}
.product-price {
  min-width: unset;
  .price {
    font-size: 18px;
  }
}
.qty-wrap {
  width: 100%;
  min-width: 130px;
  max-width: 200px;
  .val-cnt .val {
    color: $text-color;
  }
  .minus,
  .plus {
    .v-icon {
      color: $text-color;
    }
  }
}

.header {
  height: 45px;
  width: 100%;
  .icons-box {
    display: flex;
    margin-left: 3px;
  }
}
.not-available {
  color: var(--v-error-base);
  font-size: 12px;
  white-space: normal;
  height: 40px;
  display: flex;
  align-items: flex-end;
  text-align: right;
}
.product-card {
  width: 100%;
}
.ingredient-container,
.ingredient-col {
  max-height: 50px;
  margin-bottom: 16px !important;
}
.name {
  // font-size: 1rem !important;
}
.cur-price {
  // font-size: 18px !important;
  // max-width: fit-content;
}
.product-img {
  max-width: 64px;
}
.actions {
  // justify-content: flex-end !important;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .ingredient-container {
    height: auto;
    margin-bottom: 16px !important;
  }
  .product-img {
    max-width: 42px;
  }
  .name {
    font-size: 11px !important;
  }
  .qty-wrap div {
    font-size: 11px !important;
    padding: 0 4px !important;
  }
  .qty-wrap i {
    //  font-size: 18px !important;
  }
}
</style>
<script>
import { mapState, mapGetters } from "vuex";
import productMixin from "~/mixins/product";
import ProductPrice from "@/components/product/ProductPrice.vue";

export default {
  name: "ProductCard",
  props: ["product"],

  mixins: [productMixin],
  components: { ProductPrice },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticatedss: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    productClasses() {
      if (global.config.filterProductClasses && this.product.productClasses) {
        return this.product.productClasses.filter(
          p =>
            global.config.filterProductClasses.includes(p.productClassId) &&
            p.productClassTypeId == 2 &&
            p.productClassGroupId != 1
        );
      } else {
        return (
          this.product?.productClasses &&
          this.product?.productClasses.filter(
            p => p.productClassTypeId == 2 && p.productClassGroupId != 1
          )
        );
      }
    },
    item() {
      return null;
    }
  }
};
</script>
